import React, { useEffect, useState } from 'react';
import ClientInfo from './ClientInfo'
import UserInfo from './UserInfo'
import FirstQuestion from './FirstQuestion'
import StepsBar from './StepsBar'
import {
    Flex,
    Box,
    Heading,
    Text,
    Button
} from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion"

import { useFormik } from 'formik';
import './css/ContactForm.css'
import { ReactComponent as Check } from '../assets/check.svg'

import { ReactComponent as Logo } from '../assets/logocdc.svg'

function ContactForm(props) {
    const [cnpj, setCnpj] = useState();
    const [loadingCnpj, setLoadingCnpj] = useState(false);
    const [cnpjRegistered, setCnpjRegistered] = useState(false);
    const [sendingSubmitRequest, setSendingSubmitRequest] = useState(false)

    const [hasFantasyName, setHasFantasyName] = useState('');
    const [next, setNext] = useState(true);
    const [form, setForm] = useState({
        razao_social: '',
        nome_fantasia: ''
    })

    const [confirm, setConfirm] = useState(false);

    const [info, setInfo] = useState('')
    const [step, setStep] = useState(1);
    const API = process.env.REACT_APP_ENV == 'true' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_LOCALHOST;

    function handleGoLastStep() {
        setInfo('Cadastro realizado! Confirme o seu email para liberar o seu acesso.')
        setStep(4)
    }

    const formik = useFormik({
        initialValues: {
            question1: '',
            question2: '',
            question3: '',
            question4: '',
            cnpj: '',
            razao_social: '',
            nome_fantasia: '',
            estado: '',
            cidade: '',
            bairro: '',
            cep: '',
            complemento: '',
            nome: '',
            telefone: '',
            email: '',
            senha: '',
            confirma: '',
        },
        onSubmit: values => {
            let json = {
                "manager": {
                    "password": values.senha,
                    "email": values.email,
                    "phone": values.telefone,
                    "name": values.nome,
                    "login": values.email,
                    "cpf": "",
                },
                "head_office": {
                    "email": values.email,
                    "social_reazon": values.razao_social,
                    "trading_name": values.nome_fantasia,
                    "name": values.nome_fantasia,
                    "cnpj": values.cnpj,
                    "phone": values.telefone,
                    "state": {
                        "id": values.state_id,
                        "name": values.state_name,
                        "fu": values.fu
                    },
                    "city": {
                        "id": values.city_id,
                        "name": "",
                        "est_id": values.state_id
                    },
                    "zip_code": values.cep,
                    "street": values.complemento,
                    "district": values.bairro,
                    "complement": ""
                },
                "resale": {

                },
                "sectors": [
                    {
                        "id": values.question2
                    }
                ],
                "settings": [
                    {
                        "key": "CLIENT_RELEASE_STATUS"
                    }
                ],
                "attendance": {
                    "attendant": {


                    },
                    "status": {

                    }
                },
                "email": values.email,
                "name": values.razao_social,
                "group_name": values.razao_social,
                "cnpj": values.cnpj,
                "trading_name": values.nome_fantasia,
                "contact": values.nome,
                "site_url": "",
                "phone": values.telefone,
                "delivery_type_id": "",
                "order_type": "",
                "closing_method": "",
                "question1": values.question1,
                "question2": '',
                "question3": values.question3,
                "question4": values.question4,
                "categories": [
                    {
                        "id": "",
                        "name": "",
                        "parent_id": "",
                        "internal_code": "",
                        "is_active": "",
                        "created_at": ""
                    }
                ],
                "implanted": 0
            }
            setSendingSubmitRequest(true)
            fetch(API + 'create-user', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    'Content-type': 'application/json;',
                    'Accept': 'application/json'
                }
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            }).then(function (data) {
                handleGoLastStep()
                setSendingSubmitRequest(false)
            }).catch(error => {
                setSendingSubmitRequest(false)
                //setInfo('Erro ao efetuar cadastro')
                error.json().then((data) => setInfo(Object.values(data.errors)[0][0]))
            });
        },
    });

    function search(cnpj) {
        setCnpjRegistered(false)
        setLoadingCnpj(true)

        fetch("https://brasilapi.com.br/api/cnpj/v1/" + cnpj.replace(/\D/g, ""))
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()  //we only get here if there is no error
            })
            .then(data => {
                let data_array = {
                    razao_social: data.razao_social,
                    nome_fantasia: data.nome_fantasia,
                    cnpj: data.cnpj,
                    bairro: data.bairro,
                    cep: data.cep,
                    cidade: data.municipio,
                    complemento: data.descricao_tipo_de_logradouro + ' ' + data.logradouro,
                    numero: data.numero,
                    estado: data.uf,
                    telefone_cnpj: data.ddd_telefone_1,
                }
                setHasFantasyName(data.nome_fantasia)


                for (const [key, value] of Object.entries(data_array)) {
                    formik.setFieldValue(key, value);
                }
                setConfirm(false);

                fetch(API + "estate/" + data_array.estado)
                    .then(response => {
                        if (!response.ok) { throw response }
                        return response.json()
                    })
                    .then(data => {
                        fetch(API + "city/" + data_array.cidade + "/" + data.est_id)
                            .then(response => {
                                if (!response.ok) { throw response }
                                return response.json()
                            })
                            .then(data => {
                                formik.setFieldValue('city_id', data.cid_id);
                            })
                            .catch(err => {
                                err.text().then(errorMessage => {
                                })
                            })
                        formik.setFieldValue('state_id', data.est_id);
                        formik.setFieldValue('state_name', data.est_nome);
                        formik.setFieldValue('fu', data.est_sigla);
                    })
                    .catch(err => {
                        err.text().then(errorMessage => {
                        })
                    })
                setLoadingCnpj(false)
                setNext(false)

                // fetch('https://hml.api.clubdacotacao.com.br/v3/api/external/verify-cnpj?cnpj=00468956000120' + cnpj.replace(/\D/g, ""))
                fetch('https://api.clubdacotacao.com.br/v3/api/external/verify-cnpj?cnpj=' + cnpj.replace(/\D/g, ""))
                    .then(response =>
                        response.json()
                    ).then(
                        res => res.loja && (setCnpjRegistered(res.loja.length > 0))
                        // res => res.loja && (setCnpjRegistered(true))
                    )



            })
            .catch(err => {

                setConfirm(true);
                setLoadingCnpj(false);


            });
    }


    return (

        <>
            <div style={{
                //backgroundImage: `url(${process.env.PUBLIC_URL + '/bg.jpg'})`,
                //  backgroundPosition: 'center',
                //  backgroundSize: 'cover',
                //  backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100vh',


            }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',


                    }}


                // width="full" height={'full'} align="center" justifyContent="center"
                >
                    {step == 4 ?
                        <div className='containerSuccessScreen'>


                            <div className='containersvg'>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52">
                                    <circle
                                        class="checkmark__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none" />
                                    <path
                                        class="checkmark__check"
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>

                            </div>

                            <strong className='SuccessScreenTextStrong'>Cadastro realizado !</strong>
                            <p className='SuccessScreenText'><br></br>Confirme o seu email para liberar o acesso !</p>






                        </div>
                        :
                        <div style={{ backgroundColor: 'white', padding: 15 }}
                        // mt={8} 
                        >

                            {/* <Box style={{ padding: 15 }} mb={12} textAlign="center">
                            <StepsBar step={step} />
                        </Box> */}

                            <form autocomplete="on" onSubmit={formik.handleSubmit}>
                                {(() => {
                                    if (step == 1) {
                                        return (
                                            <>

                                                <Box flexDir={'row'} mb={12} textAlign="center">
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Logo
                                                            style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)' , marginBottom:15 }}
                                                        ></Logo>
                                                        <Heading as='h3' size='lg' fontWeight='normal'>Pare de perder tempo com horas<br /> no telefone!</Heading>
                                                    </div>
                                                </Box>

                                                <FirstQuestion formik={formik} setStep={setStep} setForm={setForm} />
                                            </>

                                        )
                                    }
                                    if (step == 2) {
                                        return (
                                            <>
                                                <Box mb={12} textAlign="center">
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Logo
                                                            style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)', marginBottom:15  }}
                                                        ></Logo>
                                                        <Heading as='h3' size='lg' fontWeight='normal'>Dados da empresa principal</Heading>
                                                    </div>
                                                </Box>
                                                <ClientInfo
                                                    cnpjAlreadyRegistered={cnpjRegistered}
                                                    confirm={confirm}
                                                    formik={formik}
                                                    setStep={setStep}
                                                    search={search}
                                                    setCnpj={setCnpj}
                                                    loadingCnpj={loadingCnpj}
                                                    hasFantasyName={hasFantasyName}
                                                    cnpj={cnpj} next={next}
                                                    setForm={setForm}
                                                    form={form} />
                                            </>
                                        )
                                    }
                                    if (step == 3) {
                                        return (
                                            <>
                                                <Box mb={12} textAlign="center">
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Logo
                                                            style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)', marginBottom:15 }}
                                                        ></Logo>
                                                        {sendingSubmitRequest ? <Heading as='h3' size='lg' fontWeight='normal'>Efetuando cadastro ...</Heading> :
                                                            <Heading as='h3' size='lg' fontWeight='normal'>Você está a um passo de uma <br /> cotação mais simples e prática</Heading>
                                                        }
                                                    </div>
                                                </Box>
                                                <UserInfo info={info} sendingSubmitRequest={sendingSubmitRequest} formik={formik} setStep={setStep} setForm={setForm} form={form} />
                                            </>
                                        )
                                    }
                                    return null;
                                })()}

                            </form>

                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default ContactForm;