import React from 'react';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import './css/StepsBar.css'

function StepsBar(props) {
    function progress(props){
      if (props.step == 1){
        return 0;
      }
      if(props.step == 2){
        return 50;
      }
      if(props.step == 3){
        return 100;
      }
    }

    return (
      <>        
      <ProgressBar filledBackground=" rgba(255, 113, 16, 1)" height={2} percent={progress(props)}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step>
    </ProgressBar>   

    </>
    );
}
export default StepsBar;