import React from 'react';
import { useState, useEffect } from "react";
import { CarrouselView } from '../Components/CarrouselView';
import ContactForm from '../Components/ContactForm';
import '../Components/css/Carrousel.css'

function Cadastro() {


  return (
    <div className='rootContainer'>
      <CarrouselView />
      <ContactForm />
    </div>
  );
}

export default Cadastro;