import React from 'react';
import InputMask from 'react-input-mask';
import './css/styles.css'
const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const MaskedInput = ({ value, onChange, name, mask,width }) => {
    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                name,
                value: onlyNumbers(event.target.value)
            }
        });
    }

    return (
        <InputMask
        className='inputStyle'
            name={name}
            mask={mask}
            value={value}
            onChange={handleChange}
            style={{
                padding:15,
                width: width? width: 300,
                border: '1px solid rgb(226,232,240)',
                outline: '2px solid transparent',
                borderRadius:6,
                height:40,
            }}
        />
    );
};

export default MaskedInput;
