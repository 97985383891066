import React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { isAndroid, isIOS, isDesktop } from "react-device-detect";
import '../Components/css/styles.css'
import {
    Flex,
    Box,
    Heading,
    Text,
    Spinner,
    Button,
    Alert,
    Center
} from '@chakra-ui/react'
import { useRef } from 'react';

const API = process.env.REACT_APP_ENV ? process.env.REACT_APP_API_URL : process.env.REACT_APP_LOCALHOST;

function Verify() {
    const [email, setEmail] = useState();
    const [nome, setNome] = useState();
    const [responseStatus, setResponseStatus] = useState();
    const buttonRef = useRef()



    useEffect(() => {
        handleOpenApp()
    }, [])


    let params = useParams();

    useEffect(() => {
        console.log('==>' + API);
        fetch(API + "email-verify/" + params.hash)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then(data => {
                setEmail(data.usu_email)
                setNome(data.usu_nome)
                setResponseStatus(true)
            })
            .catch((error) => {
                setResponseStatus(false)
                setEmail('Verificação expirada')
            })
    }, [])

    function handleOpenApp() {
        if (isAndroid) {
            let app = "clubdacotacao://"
            setTimeout(() => {
                window.location.replace(app)
            }, 5000)

        } else if (isIOS) {
            let iosApp = "clubdacotacao://"
            setTimeout(() => {
                window.location.replace(iosApp)
            }, 5000)
        } else {
            setTimeout(() => {

                handleContinueOnBrowser()
            }, 6000);
        }

    }
    function handleContinueOnBrowser() {

        window.location.replace(process.env.REACT_APP_ENV == 'true' ? process.env.REACT_APP_API_URL_LOGIN : process.env.REACT_APP_API_URL_LOGIN_HML);
    }

    function handlePlayStore() {
        window.location.replace('https://play.google.com/store/apps/details?id=br.com.clubdacotacao.clubdacotacao&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1');
    }
    function handleAppStore() {
        window.location.replace("https://apps.apple.com/br/app/club-da-cotacao/id1331105972")
    }

    function okResponsePage() {

        return (<>
            <Heading as='h3' size='lg' fontWeight='normal'>Bem vindo ao Club da Cotação!</Heading>
            <Text mt={12}>Seu email foi confirmado e sua conta está liberada. </Text>
            {isAndroid &&
                <div className={'buttonCellContainer'}>
                    <div onClick={() => handleContinueOnBrowser()} className={'buttonCellSecondary'}>Continuar no navegador</div>
                    <div ref={buttonRef} onClick={() => handleOpenApp()} className={'buttonCellPrimary'}>Abrir no app</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>

                        <img onClick={() => handlePlayStore()} width='60%' height="20" alt='Disponível no Google Play' src={require('../assets/google-play-badge.png')}></img>

                    </div>
                </div>
            }
            {isIOS &&
                <div className={'buttonCellContainer'} >
                    <div onClick={() => handleContinueOnBrowser()} className={'buttonCellSecondary'}>Continuar no navegador</div>
                    <div ref={buttonRef} onClick={() => handleOpenApp()} className={'buttonCellPrimary'}>Abrir no app</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>

                        <img onClick={() => handleAppStore()} width='60%' height="20" alt='Disponível na app store' 
                        src={require('../assets/appstore.png')}></img>

                    </div>
                </div>
            }

            {isDesktop && <>
                <Text mt={12}>Redirecionando... </Text>
                <Flex mt={5} width="full" align="center" justifyContent="center" mb={4}>
                    <Spinner size='xl' />
                </Flex>
            </>
            }
        </>
        )
    }

    function errorResponsePage() {
        return (<>
            <Heading as='h3' size='lg' fontWeight='normal'>Verificação falhou</Heading>
            <Text mt={12}>{email}</Text>
        </>
        )
    }

    return (
        <>


            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg.jpg'})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100%',
                minHeight: '940px'

            }}
            >
                <Flex width="full" align="center" justifyContent="center" style={{ textAlign: 'center', padding: 10 }}>
                    <Box style={{ backgroundColor: 'white' }} px='70px' py='50px' mt={8} minHeight="600px" maxWidth="700px" borderWidth={1} borderRadius={8} boxShadow="lg">
                        <Flex width="full" align="center" justifyContent="center" mb={4}>
                            <img src="https://painel.clubdacotacao.com.br/images/logotipo.png
                        " alt="" width="300" height="56" />
                        </Flex>
                        {responseStatus ?
                            okResponsePage()
                            : okResponsePage()}


                    </Box>

                </Flex>
            </div>
        </>
    );
}

export default Verify;