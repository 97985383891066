import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../assets/logocdc.svg'

import './css/Carrousel.css'
import { Card } from './Card';


export function CarrouselView() {
    const [position, setPosition] = useState(1)


    useEffect(() => {
        setTimeout(() => changePosition(), 6000);
    }, [position])

    function changePosition() {
        position < 4 ? setPosition(position + 1) : setPosition(1)

    }

  
    return (
        <div className='container'>
            <div className="header">
                <Logo
                    style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)' }}
                ></Logo>
            </div>
            <div className="body">
                <Card position={position} />
            </div>
        </div>
    );
}