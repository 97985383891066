import React, {useState, useEffect} from 'react';
import './css/styles.css'
import {
    FormLabel,
    Input,
    Box, 
    Button,
    Select, 
    HStack
  } from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion"

const API = process.env.REACT_APP_ENV == 'true' ? process.env.REACT_APP_API_URL :  process.env.REACT_APP_LOCALHOST;

function FirstQuestion(props) {

    const [value, setValue] = useState(0);
    const [segmentos, setSegmentos] =useState([]);

    const [button, setButton] = useState(false);

    useEffect(() => {
        if(props.formik.values.question1 == ''   ||  props.formik.values.question2 == '' || props.formik.values.question3 == '' || props.formik.values.question4 == ''){
            setButton(true);

        }else{
            setButton(false);
        }
    })
    useEffect(() => {
        fetch(API + "sectors")
        .then(response =>   {  if (!response.ok) { throw response }
            return response.json()  //we only get here if there is no error
        })
        .then(data => {
            setSegmentos(data)
        })
        .catch( err => {
            err.text().then( errorMessage => {
            })
          })
    }, [])

    return (
        <>
            <AnimatePresence >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                >
                <Box mb={6}>
                <FormLabel color="gray" >Você faz cotação atualmente? </FormLabel>
                    <Select className='selectStyle' value={props.formik.values.question1} onChange={(e) => {props.formik.handleChange(e)}} name="question1" color='grey' variant='filled' placeholder='Selecione' required>
                        <option value='Não, mas gostaria'>Não, mas gostaria</option>
                        <option value='Sim (Excel, Whatsapp, Email'>Sim (Excel, Whatsapp, Email)</option>
                        <option value='Sim (Presencial)'>Sim (Presencial)</option>
                        <option value='Outros'>Outros</option>
                    </Select>
                </Box>

                <Box mb={6}>
                <FormLabel color="gray" htmlFor='razao'>Segmento de atuação </FormLabel>
                    <Select className='selectStyle' onChange={(e) => { props.formik.handleChange(e)}} value={props.formik.values.question2} name="question2" color='grey' variant='filled' placeholder='Selecione' required>
                        {segmentos.map ((segmento) => <option value={segmento.ramo_id}>{segmento.ramo_descricao}</option>)}
                    </Select>
                </Box>

                <Box mb={6}>
                <FormLabel color="gray" htmlFor='razao'>Cargo </FormLabel>
                    <Select className='selectStyle' onChange={(e) => { props.formik.handleChange(e)}} value={props.formik.values.question3} name="question3" color='grey' variant='filled' placeholder='Selecione' required>
                        <option value='Sócio / Diretor'>Sócio / Diretor</option>
                        <option value='Comprador'>Comprador</option>
                        <option value='Requisitante'>Requisitante</option>
                        <option value='Outros'>Outros</option>
                    </Select>
                </Box>

                <Box mb={6}>
                <FormLabel color="gray" htmlFor='razao'>Numero de empresas </FormLabel>
                    <Select className='selectStyle' onChange={(e) => { props.formik.handleChange(e)}} value={props.formik.values.question4} name="question4" color='grey' variant='filled' placeholder='Selecione' required>
                        <option value='de 1 a 5'>de 1 a 5</option>
                        <option value='até 10'>até 10</option>
                        <option value='acima de 10'>acima de 10</option>
                    </Select>
                </Box  >
                    <Box textAlign="right">  
                        <Button isDisabled={button}  onClick={(e) => {props.setStep(2)}} justifyContent="flex-end" bg='#FF7110' _hover={{ bg: '#e6650e' }} color='white'>Próximo</Button>
                    </Box>
                </motion.div>
            </AnimatePresence>
        </>
    );
}
export default FirstQuestion;