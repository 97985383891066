import "@fontsource/rubik";

import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Cadastro from "./Pages/Cadastro";
import Verify from "./Pages/Verify";
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme'


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Cadastro />} />
        <Route path="verify/:hash" element={<Verify />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);